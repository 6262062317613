import * as React from "react";
import { XIcon } from "@heroicons/react/outline";

export const Inputs = ({ inputsData, labelTitle }) => {
  return (
    <div>
      <fieldset>
        <legend className="whitespace-nowrap block text-xsm font-medium text-primary-dark-blue base-title ">
          {labelTitle}
        </legend>
        <div className="mt-2 -space-y-px rounded-md">
          <div className="flex -space-x-px">
            {inputsData.map((item) => (
              <div className={"w-1/2 min-w-0 flex-1 "} key={item.name}>
                <label htmlFor={item.name} className="text-center">
                  {item.label}
                </label>
                <div className="flex items-center justify-center">
                  <input
                    type="number"
                    name={item.name}
                    id={item.name}
                    className="input-field mt-1 uppercase"
                    value={item.value}
                    ref={item.ref}
                  />
                  {item.icon && <XIcon className="h-4 px-2" />}
                </div>
              </div>
            ))}
          </div>
        </div>
        <p className="mt-2">Dimensiones máximas de 30x30x30</p>
      </fieldset>
    </div>
  );
};
