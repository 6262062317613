import * as React from 'react';

import { forwardRef } from 'react';
const Checkbox = ({ name, value, checked, ...props },ref) => {
    return (
        <div className="relative flex items-start">
            <div className="flex items-center h-5">
                <input
                    id={name}
                    name={name}
                    ref={ref}
                    type="checkbox"
                    // value={value}
                    defaultChecked={checked}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                />
            </div>
            <div className="ml-3">
                <label htmlFor={name}>
                    {props.children}
                </label>
            </div>
        </div>
    )
}

export default forwardRef(Checkbox)