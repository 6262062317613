import { useEffect } from "react";
import { navigate } from "gatsby";

export const useSubscriptions = ({ eventBus, route }) => {
  useEffect(() => {
    const subscriptions = eventBus.subscribe("QUOTE_RETRIEVED", () => {
      // ? Recupera los datos de la cotización y redirige a la ventana siguente: Detalles
      navigate(route);
    });
    // ?? unsubscribes the event listener when the component unmounts
    return () => {
      subscriptions.unsubscribe();
    };
  }, [eventBus]);
};
