import React from "react";
import QuoteRequestForm from "../components/forms/quote-request";
import Navbar from "../components/global/navbar";
import Layout from "../components/global/layout";
import PageTitle from "../components/global/page-title";

const CotizarEnvio = () => {
  return (
    <>
      <Navbar />
      <Layout>
        <PageTitle>Cotizar Envío</PageTitle>
        <QuoteRequestForm />
      </Layout>
    </>
  );
};

export default CotizarEnvio;
