import { getQuoteFor, UPDATE_QUOTE_PARAMS } from "app/store/applicationReducer"
import { useRef, useState } from "react"
import InputWithLabel from "./input-with-label"
import { useStore } from "app/store/store"
import Checkbox from "./checkbox"
import { Link } from "gatsby"
import PopOver from "./pop-over"
import * as React from "react"
import Select from "./select"
import { COTIZANDO, SERVICES_TYPE } from "../../app/constants"
import { useStateChanges } from "../../hooks/useStatesChanges"
import { useSubscriptions } from "../../hooks/useSubscriptions"
import { useLocationGraph } from "../../hooks/useLocationGraph"
import validate from "validate.js"
import { quoteFieldsValidation } from "../../app/services/validation"
import { useEventBus } from "../../hooks/useEventBus"
import { Errors } from "../global/Errors"
import { Button } from "../global/Button"
import { Inputs } from "../forms/Inputs"
import Modal from "./modal"

const QuoteRequestForm = () => {
  // ? we can use the global state since its a reducer, its already reactive
  const [globalState, middleWareDispatcher, eventBus] = useStore()
  // ?? destructuring the state to make it easy to work with additional data
  const { weight, serviceType, termsAcceptance, merchandise, length, width, height } =
    globalState.quote.params
  const {
    setDestinationLocations,
    setCurrentDestination,
    setCurrentOrigin,
    destinationLocations,
    currentDestination,
    originLocations,
    currentOrigin,
  } = useStateChanges({ globalState })
  const [fieldsErrors, setFieldsErrors] = useState("")
  // * Referencias de los inputs
  const weightRef = useRef(null)
  const termsAcceptanceRef = useRef(null)
  const merchandiseRef = useRef(null)
  const formRef = useRef(null)
  const heightRef = useRef(null)
  const widthRef = useRef(null)
  const lengthRef = useRef(null)

  const packageDimensions = [
    {
      label: 'Largo',
      name: 'length',
      icon: true,
      value: length,
      ref: lengthRef
    },
    {
      label: 'Ancho',
      name: 'width',
      icon: true,
      value: width,
      ref: widthRef
    },
    {
      label: 'Alto',
      name: 'height',
      value: height,
      ref: heightRef
    }
  ]
  useSubscriptions({ eventBus, route: "/detalles-de-la-cotizacion" })

  // * Indica el origen y destino, indicados en los selectores
  function handleLocationChange(name) {
    return (selectedNode) => {
      // ?? El siguiente objeto contiene los datos seleccionados del selector
      // * origen actual, destino actual, id del origen, id del destino
      const _actionHelper = {
        origin: setCurrentOrigin,
        destination: setCurrentDestination,
        originId: (name === "origin" && selectedNode.id) || currentOrigin?.id,
        destinationId:
          (name === "destination" && selectedNode.id) || currentDestination?.id,
      }
      // ?? get the destinations from origin
      const destinations = useLocationGraph(_actionHelper)
      // ?? destinations are supposed to be guaranteed to be reachable from origin
      _actionHelper[name](selectedNode)
      // ?? Aquí se definen los destinos
      if (name === "origin") {
        setDestinationLocations(destinations)
      }
    }
  }
  // ?? form submit
  const onSubmit = (event) => {
    event.preventDefault()
    const entryFields = globalState.quote.params
    // TODO: Sacar las validaciones
    const fieldsValidated = validate(entryFields, quoteFieldsValidation)
    // * Primer caso
    fieldsValidated !== null && fieldsValidated !== undefined
      ? setFieldsErrors(Object.values(fieldsValidated))
      : setFieldsErrors("")
    // * Segundo caso
    if (
      fieldsValidated !== null &&
      fieldsValidated !== undefined &&
      Object.values(fieldsValidated).length > 0
    )
      return
    // todo: END
    // ? Todo salio bien mandamos una notificacion informando que ser esta realizando la cotizacion
    useEventBus({ data: COTIZANDO, eventBus })
    // * Verifica que la cotización sea valida teniendo en cuenta los datos enviados por el usuario
    middleWareDispatcher(
      getQuoteFor({ token: globalState.authToken, ...entryFields })
    )
  }

  // ?? regular form change
  const onChange = ({ target: { name, value } }) => {
    // ?? pass it down to the custom change handler
    customChange(name)(value)
  }

  // ?? handles any change specifically from custom components
  const customChange = (name) => {
    return (value) => {
      // ?? we can do the validations here
      value =
        name === "termsAcceptance" ? termsAcceptanceRef.current.checked : value
      value =
        name === "serviceType"
          ? SERVICES_TYPE.find((el) => el.name === value).id
          : value
      // ?? make a copy to pass to the new state
      const quoteParams = { ...globalState.quote.params }
      quoteParams[name] = value
      middleWareDispatcher({ type: UPDATE_QUOTE_PARAMS, payload: quoteParams })
    }
  }
  return (
    <form
      ref={formRef}
      className="flex flex-col"
      action="/detalles-de-la-cotizacion"
      onSubmit={onSubmit}
      onChange={onChange}
    >
      <>
        <div className="md:w-9/10 md:mx-auto md:my-10 md:rounded-2xl md:shadow-md md:pt-8 overflow-hidden">
          <Errors fieldsErrors={fieldsErrors} />
          {/* * filter origins and destinations  */}
          <div className="md:grid md:grid-cols-2 md:gap-6 md:px-12 lg:px-24">
            <div>
              <Select
                name="origin"
                labelIsTitle
                label="Origen"
                showValue={false}
                selectData={originLocations.filter((node) => node.out === true)}
                selectedItem={currentOrigin}
                placeholder="Origen"
                onChange={handleLocationChange("origin")}
              />
            </div>
            <div>
              <Select
                name="destination"
                labelIsTitle
                label="Destino"
                showValue={false}
                selectData={destinationLocations.filter(
                  (node) => node.in === true
                )}
                selectedItem={currentDestination}
                placeholder="Destino"
                onChange={handleLocationChange("destination")}
              />
            </div>
          </div>
          <div className="mt-4 md:px-12 lg:px-24">
            <div className="grid grid-cols-1 gap-6">
              <div className="block base-title text-primary-gray">Detalles</div>
            </div>
            <div className="grid grid-cols-3 gap-6">
              <div className="col-span-3 md:col-span-1">
                <InputWithLabel
                  value={weight}
                  type="number"
                  label="Peso del paquete (kg)"
                  name="weight"
                  ref={weightRef}
                />
              </div>
              <div className="col-span-3 md:col-span-2">
                <Select
                  name="serviceType"
                  label={<PopOver button="Tipo de servicio" />}
                  selectData={SERVICES_TYPE}
                  selectedItem={SERVICES_TYPE.find((el) => el.id === serviceType)}
                  placeholder="-"
                />
              </div>
              <div className="col-span-3">
                <Inputs
                  labelTitle={"DIMENSIONES DEL PAQUETE "}
                  inputsData={packageDimensions}
                />
              </div>
              <div className="col-span-3">
                <InputWithLabel
                  value={merchandise}
                  label="Tipo de mercancia"
                  labelIsTitle
                  name="merchandise"
                  ref={merchandiseRef}
                  placeholder="Ropa / Documentos / Accesorios / Libros / Otro"
                />
              </div>
            </div>
          </div>
          <div className="md:bg-gray-200 md:bg-opacity-20 md:px-12 lg:px-24 md:pb-8 md:pt-6 md:mt-12">
            <div className="py-4 flex md:justify-center">
              <Checkbox
                name="termsAcceptance"
                ref={termsAcceptanceRef}
                checked={termsAcceptance}
              >
                <span>
                  Acepto{" "}
                  <Link to="/terminos-y-condiciones">
                    <strong className="hover:underline decoration-gray-600">
                      {" "}
                      Términos y condiciones{" "}
                    </strong>
                  </Link>{" "}
                  y
                  <Link to="/aviso-privacidad">
                    <strong className="hover:underline decoration-gray-600">
                      {" "}
                      Aviso de privacidad
                    </strong>
                  </Link>
                </span>
              </Checkbox>
            </div>
            <div>
              <div className="max-w-sm mx-auto sm:max-w-none flex justify-center px-4">
                <Button
                  text={"Cotizar"}
                  iconDirection={true}
                  name={"submitBtn"}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    </form>
  )
}

export default QuoteRequestForm
