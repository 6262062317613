import { useEffect, useState } from "react";
import { useLocationGraph } from "./useLocationGraph";
export const useStateChanges = ({ globalState }) => {
  // ? Estados Origen
  const [originLocations, setOriginLocations] = useState([]);
  // ? Estados Destino
  const [destinationLocations, setDestinationLocations] = useState([]);
  const [currentOrigin, setCurrentOrigin] = useState(null);
  const [currentDestination, setCurrentDestination] = useState(null);

  useEffect(() => {
    localStorage.setItem('nodes', JSON.stringify(globalState.nodes))
    if (localStorage.getItem('nodes')) {
      setOriginLocations(JSON.parse(localStorage.getItem('nodes')));
    } else {
      setOriginLocations(globalState.nodes)
    }
  }, [globalState.nodes, setOriginLocations]);

  useEffect(() => {
    const origin = globalState.quote?.params?.origin || false;
    const destination = globalState.quote?.params?.destination || false;
    // ?? bail since data is not ready
    if (!origin || originLocations.length < 1) return;
    // ?? Buscamos en los origenes el origen indicado
    const _currentOrigin = originLocations.find((node) => node.name === origin);
    // ?? Si el origen es undefined, el origen actual es null, el _currentOrigin viene como objeto
    if (_currentOrigin === undefined) {
      setCurrentOrigin(null);
      return;
    }
    // ?? Origen actual
    setCurrentOrigin(_currentOrigin);
    // ?? initialize destinations only when an origin has been selected
    // ?? available destinations for current origin
    const destinations = useLocationGraph(_currentOrigin);
    if (!destination || originLocations.length < 1) return;
    // ?? Buscamos en los destinos el destino indicado
    const _currentDestination = originLocations.find(
      (node) => node.name === destination
    );
    // ?? make sure that the destination is reachable from the origin
    if (
      destinations.find((node) => node.id === _currentDestination.id) !==
      undefined
    ) {
      setCurrentDestination(_currentDestination);
    }
    // ?? Destinos
    setDestinationLocations(destinations);
  }, [
    globalState.quote.params.origin,
    globalState.quote.params.origin,
    originLocations,
    setCurrentOrigin,
    setCurrentDestination,
  ]);
  return {
    setDestinationLocations,
    setCurrentDestination,
    setCurrentOrigin,
    destinationLocations,
    currentDestination,
    originLocations,
    currentOrigin,
  };
};
